 <template>
     <v-dialog v-model="dialog" width="1200">
         <template v-slot:activator="{ on, attrs }">
             <v-btn icon v-bind="attrs" v-on="on">
                 <v-icon>mdi-eye-outline</v-icon>
             </v-btn>
         </template>
 
         <v-card class="px-5 pt-5 pb-5">
             <v-card-title class="justify-left d-flex flex-row pb-2 align-start">
                 <h2 class="text-uppercase">Imagen</h2>
                 <v-spacer></v-spacer>
                 <v-btn icon color="primary" @click="dialog = false">
                     <v-icon>mdi-close</v-icon>
                 </v-btn>
             </v-card-title>
             <v-card-text>
                 <v-container fluid>
                     <v-row>
                         <v-col col="12" md="12">
                             <v-img :src="item.photo_url" :alt="item.name"></v-img>
                         </v-col>
                     </v-row>
                 </v-container>
             </v-card-text>
         </v-card>
     </v-dialog>
 
 </template>


<script>
export default {
    name: 'ImageModal',
    props: ['item'],
    data: () => ({
        dialog: false
    })
}
</script>

<style>
</style>